import * as VueGoogleMaps from 'vue2-google-maps'
import { gmapApi } from 'vue2-google-maps'
import GmapInfoWindow from 'vue2-google-maps/src/components/infoWindow'
import GmapMap from 'vue2-google-maps/src/components/map'
import GmapMarker from 'vue2-google-maps/src/components/marker'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import Vue from 'vue'

export default {
  beforeCreate() {
    Vue.use(VueGoogleMaps, {
      load: {
        key: this.$store.state.globals.settings.integrations.google_map_browser_key, // TODO key from globals
        libraries: 'places',
        installComponents: false,
      },
    })
  },
  async mounted() {
    await this.$refs.map.$mapPromise
    this.fitBounds()
  },
  props: {
    markers: {
      type: Array,
      validator: value => {
        return value && value.length > 0
      },
    },
  },
  mixins: [ImageUrlMixin],
  components: {
    GmapInfoWindow,
    GmapMap,
    GmapMarker,
  },
  data() {
    return {
      currentMarkerIndex: null,
      infoWindowOpen: false,
      infoWindowOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoWindowPosition: null,
      mapCenter: { lat: 0, lng: 0 },
      zoom: 13,
    }
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    fitBounds() {
      if (this.markers.length === 0) {
        this.mapCenter = { lat: 49.2021611, lng: 16.507921 } // Set Prague
        this.zoom = 7
        return
      }
      if (this.markers.length === 1) {
        this.mapCenter = { lat: Number(this.markers[0].position.lat), lng: Number(this.markers[0].position.lng) }
        return
      }
      const bounds = new google.maps.LatLngBounds()
      for (let marker of this.markers) {
        bounds.extend({ lat: Number(marker.position.lat), lng: Number(marker.position.lng) })
      }
      this.$refs.map.fitBounds(bounds)
    },
    toggleInfoWindow(marker, idx) {
      const image = marker.image
        ? `<img src="${this.imageUrl({
            src: marker.image,
            width: this.$themeSettings.components.GoogleMap.marker.image.width,
            height: this.$themeSettings.components.GoogleMap.marker.image.height,
            crop: this.$themeSettings.components.GoogleMap.marker.image.crop,
          })}">`
        : ''
      this.infoWindowPosition = { lat: Number(marker.position.lat), lng: Number(marker.position.lng) }
      this.infoWindowOptions.content = `
        <div class="GoogleMap__marker">
          <div class="GoogleMap__markerTitle">
            ${marker.title}
          </div>
          ${image}
          <div class="GoogleMap__markerDescription">
            ${marker.markerDescription}
          </div>
        </div>
      `
      if (this.currentMarkerIndex === idx) {
        this.infoWindowOpen = !this.infoWindowOpen
      } else {
        this.infoWindowOpen = true
        this.currentMarkerIndex = idx
      }
    },
  },
  watch: {
    markers() {
      this.infoWindowOpen = false
      this.fitBounds()
    },
  },
}
